/*
全局样式，不要轻易编写全局样式
*/
@import "//at.alicdn.com/t/font_1941350_h1bkay939hc.css";
html,
body,
#root {
  height: 100%;
}
@page {
  size: auto;
  margin: 0mm;
}
body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #7E8B97;
}
/**滚动条*/
::-webkit-scrollbar {
  width: 4px;
  height: 7px;
}
::-webkit-scrollbar-thumb {
  background: #D2DAE1;
  border-radius: 6px;
}
::-webkit-scrollbar-track {
  border-radius: 6px;
  background: rgba(250, 250, 250, 0.9);
}
/* .ant-menu-item > a {
    color: #fff !important;
} */
#nprogress .bar {
  z-index: 9999;
}
.ant-table-thead {
  background-color: #ffffff !important;
}
:global(.anticon)::before {
  font-family: "PingFang SC" !important;
  display: inline-block;
}
.ant-form label,
.ant-form-explain {
  font-size: 12px;
}
/*.ant-form-item-label,*/
/*.ant-form-item-control {*/
/*line-height: 25px;*/
/*}*/
/*.ant-tooltip,*/
/*.ant-modal-mask,*/
/*.ant-modal-wrap,*/
/*.ant-message,*/
/*.ant-menu-submenu-popup,*/
/*.ant-notification {*/
/*z-index: 9999 !important;;*/
/*}*/
/*.ant-modal {*/
/*padding-bottom: 84px !important;*/
/*}*/
/* 只在打印时显示 */
.just-print {
  display: none !important;
}
@media print {
  body {
    padding: 0 !important;
    background: none;
  }
  /* 打印时不显示 */
  .no-print {
    display: none !important;
  }
  .just-print {
    display: block !important;
  }
  .ant-message {
    display: none !important;
  }
  .ant-modal-mask {
    display: none !important;
  }
}
.flex_layout_contianer {
  flex: 1;
  overflow: hidden;
}
.common_layout_container {
  position: "absolute";
  top: "50px";
  left: 0;
  height: "calc(100% - 74px)";
  width: "100%";
  overflow: "hidden";
}
.common_ant_modal {
  height: 600px;
  overflow: auto;
}
/* .ant-modal-body {
    height: 500px;
    overflow: auto;
} */
/* .ant-table-body {
    min-height: 420px;
} */
.flod_icon {
  position: absolute;
  height: 34px;
  width: 34px;
  line-height: 34px;
  border: 0;
  border-left: 0;
  border-radius: 50%;
  z-index: 1;
  display: none;
}
.more_query {
  overflow: hidden;
  transition: all 0.6s;
}
.header_layout {
  margin-bottom: 12px;
}
.content_layout {
  background-color: #fff;
  flex: 1;
}
.sider_layout {
  margin-right: 12px;
}
.common_color {
  background-color: #eff2f5 !important;
}
.ant-tabs-content {
  height: calc(100% - 70px);
}
/* .ant-tabs-top-content > .ant-tabs-tabpane {
    flex-shrink: 1 !important;
} */
.print_hide {
  visibility: hidden !important;
  display: none !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 12px 12px !important;
  overflow-wrap: break-word;
  font-size: 12px;
}
.ant-table-thead > tr > th {
  color: #000;
}
.ant-table-bordered .ant-table-thead > tr > th,
.ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 0px;
}
.ant-table-bordered .ant-table-header > table,
.ant-table-bordered .ant-table-body > table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
  border: 0px;
}
.ant-table-placeholder,
.ant-card-head {
  border-bottom: 0px;
}
.ant-form label,
.ant-form-explain {
  font-size: 14px;
}
.ant-tree-node-selected {
  width: 90%;
}
.ant-tree-node-selected .ant-tree-title {
  color: #738BF1;
  display: inline-block;
  width: 100%;
}
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(47, 84, 235, 0) !important;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
  color: #B2B9C1 !important;
  font-size: 14px !important;
}
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
  color: #B2B9C1 !important;
  font-size: 14px !important;
}
.ant-tree li ul {
  padding: 0px !important;
}
.treeList .ant-tree li ul {
  padding-left: 15px !important;
}
.ant-tabs-bar,
.ant-form {
  background-color: #ffffff;
}
.ant-card-head-title {
  padding-bottom: 0px !important;
}
.ant-card-head {
  border-bottom: 0px !important;
  padding: 0 30px !important;
}
.ant-form-item-label {
  text-align: left !important;
  text-overflow: ellipsis;
}
.line-btn:after {
  /*伪元素是行内元素 正常浏览器清除浮动方法*/
  content: "";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clearfix {
  *zoom: 1;
  /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}
.report-setting {
  margin-right: 0px;
}
.ant-tabs-nav-container {
  padding: 0 30px;
}
.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-y: hidden;
}
.ant-layout {
  overflow: hidden;
}
.ant-card,
.ant-spin-nested-loading,
.ant-spin-container {
  height: 100% !important;
  position: initial !important;
}
.layoutMargin {
  margin: 0 16px 15px 16px !important;
}
.layoutMargin .ant-card-body {
  padding: 20px 30px 10px !important;
}
.layoutMargin .ant-layout-sider-children .ant-input-search .ant-input {
  background: #F8FAFB;
  border-radius: 8px;
  border: 0;
  min-height: 36px;
  line-height: 36px;
}
.layoutMargin .ant-input-number,
.layoutMargin .ant-input,
.layoutMargin .ant-select-selection {
  min-height: 36px;
  border: 1px solid #E7EDF3;
  background: #F8FAFB;
  border-radius: 8px;
  line-height: 36px;
}
.layoutMargin .ant-select-selection__rendered {
  line-height: 36px;
}
.layoutMargin .ant-tabs-bar {
  border: 0;
}
.layoutMargin .ant-tabs-bar .ant-tabs-nav-scroll {
  font-size: 16px;
}
.layoutMargin .ant-card-bordered {
  border: 0px;
  border-radius: 20px;
  position: relative !important;
}
.layoutMargin .searchbg {
  background-color: #fff;
  margin-bottom: 20px;
  border-radius: 20px;
  padding: 20px 30px;
}
.layoutMargin .tabsbg {
  height: 100%;
  background: #fff;
  border-radius: 20px;
  position: initial;
}
.ant-breadcrumb {
  padding: 16px;
  font-size: 12px;
  padding-left: 35px;
  color: #7E8B97;
}
.ant-breadcrumb > span:last-child a {
  color: #000;
}
.searchbtn {
  position: absolute;
  top: 70px;
  right: 16px;
}
.searchFormContainer {
  background-color: #fff;
  padding: 15px 30px;
  margin-bottom: 8px;
  border-radius: 20px;
}
.ant-tabs-tabpane .searchFormContainer {
  background-color: #fff;
  padding: 10px 30px;
  margin-bottom: 0;
  border-bottom: 1px solid #E8EEF3;
  border-radius: 0;
}
.ant-tabs-tabpane .searchbg {
  background-color: #fff;
  margin-bottom: 0;
  border-radius: 0;
  padding: 20px 30px 10px;
  border-bottom: 1px solid #E8EEF3;
}
.ant-btn {
  height: 32px;
  border-radius: 8px;
  font-size: 12px;
  color: #7E8B97;
  border: 1px solid #E7EDF3;
}
.ant-btn-lg {
  height: 40px;
  font-size: 16px;
}
.ant-modal-content {
  border-radius: 16px;
  box-shadow: 0px 13px 30px rgba(44, 47, 50, 0.08);
}
.ant-modal-content .ant-modal-header {
  border-radius: 16px 16px 0 0;
}
.ant-modal-content .ant-input-search .ant-input {
  border-radius: 8px;
  color: #7E8B97;
}
.ant-modal-content .ant-layout-sider-children .ant-input-search .ant-input {
  background: #F8FAFB;
  border-radius: 8px;
  border: 0;
  min-height: 36px;
  line-height: 36px;
  font-size: 12px;
  color: #7E8B97;
}
.ant-modal-content .ant-input-number,
.ant-modal-content .ant-input,
.ant-modal-content .ant-select-selection {
  min-height: 36px;
  border: 1px solid #E7EDF3;
  background: #F8FAFB;
  border-radius: 8px;
  font-size: 12px;
  line-height: 36px;
  color: #7E8B97;
}
.ant-modal-content .ant-select-selection__rendered {
  line-height: 36px;
}
.modalshelft .ant-modal-content .ant-modal-footer {
  text-align: center;
  border: 0;
}
.mobilepreviewliest .viewer-container {
  width: 100% !important;
  height: 200px !important;
}
.mobilepreviewliest .viewer-container .viewer-canvas img {
  height: 100% !important;
  margin-left: 10px !important;
  margin-top: 10px !important;
  width: 100% !important;
}
.mobilepreviewliest .viewer-container .viewer-toolbar {
  display: none;
}
.docDescribe .ant-form-item .ant-form-item-label {
  text-align: left !important;
}
.docDescribe .ant-form-item .ant-form-item-label > label {
  color: #7E8B97;
}
.ant-pagination-options .ant-select-selection--single {
  min-height: 30px;
  border: 1px solid #E7EDF3;
  background: #F8FAFB;
  border-radius: 8px;
  line-height: 30px;
}
.ant-pagination-options .ant-select-selection__rendered {
  line-height: 30px;
}
/*

档案室实景视图样式*/
.listbg0,
.listbg6,
.listbg12 {
  background: rgba(95, 69, 244, 0.08);
}
.listbg0 .list_action0,
.listbg6 .list_action0,
.listbg12 .list_action0,
.listbg0 .list_action6,
.listbg6 .list_action6,
.listbg12 .list_action6,
.listbg0 .list_action12,
.listbg6 .list_action12,
.listbg12 .list_action12 {
  background: #5f45f4;
}
.listbg1,
.listbg7,
.listbg13 {
  background: rgba(0, 125, 253, 0.08);
}
.listbg1 .list_action1,
.listbg7 .list_action1,
.listbg13 .list_action1,
.listbg1 .list_action7,
.listbg7 .list_action7,
.listbg13 .list_action7,
.listbg1 .list_action13,
.listbg7 .list_action13,
.listbg13 .list_action13 {
  background: #007dfd;
}
.listbg2,
.listbg8,
.listbg14 {
  background: rgba(247, 176, 0, 0.08);
}
.listbg2 .list_action2,
.listbg8 .list_action2,
.listbg14 .list_action2,
.listbg2 .list_action8,
.listbg8 .list_action8,
.listbg14 .list_action8,
.listbg2 .list_action14,
.listbg8 .list_action14,
.listbg14 .list_action14 {
  background: #f7b000;
}
.listbg3,
.listbg9,
.listbg15 {
  background: rgba(226, 117, 117, 0.08);
}
.listbg3 .list_action3,
.listbg9 .list_action3,
.listbg15 .list_action3,
.listbg3 .list_action9,
.listbg9 .list_action9,
.listbg15 .list_action9,
.listbg3 .list_action15,
.listbg9 .list_action15,
.listbg15 .list_action15 {
  background: #e27575;
}
.listbg4,
.listbg10,
.listbg16 {
  background: rgba(57, 189, 61, 0.08);
}
.listbg4 .list_action4,
.listbg10 .list_action4,
.listbg16 .list_action4,
.listbg4 .list_action10,
.listbg10 .list_action10,
.listbg16 .list_action10,
.listbg4 .list_action16,
.listbg10 .list_action16,
.listbg16 .list_action16 {
  background: #39bd3d;
}
.listbg5,
.listbg11,
.listbg17 {
  background: rgba(75, 186, 225, 0.08);
}
.listbg5 .list_action5,
.listbg11 .list_action5,
.listbg17 .list_action5,
.listbg5 .list_action11,
.listbg11 .list_action11,
.listbg17 .list_action11,
.listbg5 .list_action17,
.listbg11 .list_action17,
.listbg17 .list_action17 {
  background: #4bbae1;
}
.listchecked0,
.listchecked6,
.listchecked12 {
  background-color: rgba(95, 69, 244) !important;
  color: #fff;
}
.listchecked0 .spanNum,
.listchecked6 .spanNum,
.listchecked12 .spanNum {
  color: #fff !important;
}
.listchecked1,
.listchecked7,
.listchecked13 {
  background-color: rgba(0, 125, 253) !important;
  color: #fff;
}
.listchecked1 .spanNum,
.listchecked7 .spanNum,
.listchecked13 .spanNum {
  color: #fff !important;
}
.listchecked2,
.listchecked8,
.listchecked14 {
  background-color: rgba(247, 176, 0) !important;
  color: #fff;
}
.listchecked2 .spanNum,
.listchecked8 .spanNum,
.listchecked14 .spanNum {
  color: #fff !important;
}
.listchecked3,
.listchecked9,
.listchecked15 {
  background-color: rgba(226, 117, 117) !important;
  color: #fff;
}
.listchecked3 .spanNum,
.listchecked9 .spanNum,
.listchecked15 .spanNum {
  color: #fff !important;
}
.listchecked4,
.listchecked10,
.listchecked16 {
  background-color: rgba(57, 189, 61) !important;
  color: #fff;
}
.listchecked4 .spanNum,
.listchecked10 .spanNum,
.listchecked16 .spanNum {
  color: #fff !important;
}
.listchecked5,
.listchecked11,
.listchecked17 {
  background-color: rgba(75, 186, 225) !important;
  color: #fff;
}
.listchecked5 .spanNum,
.listchecked11 .spanNum,
.listchecked17 .spanNum {
  color: #fff !important;
}
.listbg0:hover,
.listbg6:hover,
.listbg12:hover {
  background-color: rgba(95, 69, 244) !important;
  color: #fff;
}
.listbg0:hover .spanNum,
.listbg6:hover .spanNum,
.listbg12:hover .spanNum {
  color: #fff !important;
}
.listbg1:hover,
.listbg7:hover,
.listbg13:hover {
  background-color: rgba(0, 125, 253) !important;
  color: #fff;
}
.listbg1:hover .spanNum,
.listbg7:hover .spanNum,
.listbg13:hover .spanNum {
  color: #fff !important;
}
.listbg2:hover,
.listbg8:hover,
.listbg14:hover {
  background-color: rgba(247, 176, 0) !important;
  color: #fff;
}
.listbg2:hover .spanNum,
.listbg8:hover .spanNum,
.listbg14:hover .spanNum {
  color: #fff !important;
}
.listbg3:hover,
.listbg9:hover,
.listbg15:hover {
  background-color: rgba(226, 117, 117) !important;
  color: #fff;
}
.listbg3:hover .spanNum,
.listbg9:hover .spanNum,
.listbg15:hover .spanNum {
  color: #fff !important;
}
.listbg4:hover,
.listbg10:hover,
.listbg16:hover {
  background-color: rgba(57, 189, 61) !important;
  color: #fff;
}
.listbg4:hover .spanNum,
.listbg10:hover .spanNum,
.listbg16:hover .spanNum {
  color: #fff !important;
}
.listbg5:hover,
.listbg11:hover,
.listbg17:hover {
  background-color: rgba(75, 186, 225) !important;
  color: #fff;
}
.listbg5:hover .spanNum,
.listbg11:hover .spanNum,
.listbg17:hover .spanNum {
  color: #fff !important;
}
.viewTree {
  padding: 0 20px !important;
}
.viewTree li {
  position: relative;
}
.viewTree li .ant-tree-node-content-wrapper {
  height: 36px !important;
  line-height: 36px !important;
  padding: 0 12px 0 30px !important;
  width: 100%;
}
.viewTree li span.ant-tree-switcher {
  position: absolute !important;
  left: 5px;
  line-height: 36px !important;
}
.viewTree li span.ant-tree-switcher_open {
  color: #006939;
}
.viewTree li .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
  background: #f0f7ff !important;
  border-radius: 10px !important;
  color: #006939 !important;
}
.viewTree .ant-tree-child-tree-open li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background: none !important;
  border-radius: 0 !important;
}
.viewTree .ant-tree-child-tree-open .ant-tree-node-selected .ant-tree-title {
  color: #006939;
}
.viewTree .ant-tree-child-tree-open .ant-tree-treenode-switcher-close,
.viewTree .ant-tree-child-tree-open .ant-tree-treenode-switcher-open {
  margin-left: 20px;
  height: 36px !important;
  line-height: 36px !important;
}
.ant-dropdown-menu {
  padding: 4px 10px;
  border: 1px solid #E8EEF3;
  box-shadow: 0px 13px 30px rgba(44, 47, 50, 0.08);
  border-radius: 8px !important;
}
.ant-dropdown-menu-item:hover {
  background: #F8FAFB !important;
  border-radius: 4px;
}
.ant-dropdown-menu-item,
.ant-dropdown-menu-item > a {
  text-align: center;
  color: #000;
}
.ant-dropdown-menu-item:first-child {
  margin-top: 10px;
}
.ant-dropdown-menu-item:last-child {
  margin-bottom: 10px;
}
.ant-dropdown-menu-item > a:hover {
  color: #006939;
}
.ant-table-tbody > tr > td {
  border-bottom: none !important;
}
.ant-checkbox-inner {
  border: 1px solid #D8DCE0;
  border-radius: 4px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
  border: 1px solid #E8EEF3;
  border-radius: 4px;
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  margin-right: 10px;
}
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) .ant-pagination-item a {
  color: #7E8B97 !important;
}
.ant-pagination.mini .ant-pagination-item {
  background: #006939;
  border-radius: 4px;
  min-width: 34px;
  height: 34px;
  line-height: 34px;
  margin-right: 10px;
}
.ant-pagination.mini .ant-pagination-item .ant-pagination-item-active a {
  color: #fff;
}
.ant-pagination-item-active {
  background-color: #006939;
}
.ant-pagination.mini .ant-pagination-prev {
  min-width: 34px;
  height: 34px;
  line-height: 32px;
  margin-right: 10px;
  border: 1px solid #E8EEF3;
}
.ant-pagination.mini .ant-pagination-next {
  min-width: 34px;
  height: 34px;
  line-height: 32px;
  border: 1px solid #E8EEF3;
}
.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
  color: #7E8B97;
  left: 130px;
  position: absolute;
  line-height: 34px;
}
.ant-pagination-total-text {
  color: #7E8B97;
  left: 130px;
  position: absolute;
  line-height: 34px;
}
.ant-pagination-options {
  left: 30px;
  margin-left: 0px;
  position: absolute;
}
.ant-pagination.mini .ant-pagination-options {
  left: 30px;
  margin-left: 0px;
  position: absolute;
}
.ant-pagination-options .ant-select-selection--single {
  min-height: 34px;
  line-height: 34px;
}
.ant-select-selection-selected-value {
  font-size: 12px;
  color: #7E8B97;
}
.ant-select-selection--multiple .ant-select-selection__choice {
  color: #7E8B97;
}
.ant-pagination-options-size-changer.ant-select {
  margin-right: 0px;
}
.ant-pagination-options .ant-select-selection__rendered {
  line-height: 32px;
}
.ant-form {
  color: #000;
}
.ant-form-item-label label {
  color: #000;
}
.formCol:nth-child(3n) .formItems {
  margin-left: 16px;
}
.formCol:nth-child(3n+2) .formItems {
  margin-left: 16px;
}
.formContainer {
  background-color: #fff;
  max-height: 100px;
  overflow-y: auto;
  overflow-x: hidden;
}
.row-table .ant-pagination.mini .ant-pagination-options {
  left: 0px;
  margin-left: 0px;
  position: absolute;
}
.ant-card-extra {
  padding: 20px 0 0 !important;
}
.ant-calendar-picker {
  width: 100% !important;
}
.org-unit-control,
.org-dept-control,
.org-user-control {
  border-radius: 8px;
}
.org-unit-control .data-empty,
.org-dept-control .data-empty,
.org-user-control .data-empty {
  color: #7E8B97;
}
.ant-select-selection--multiple > ul > li,
.ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
  margin-top: 8px !important;
}
.sideNew {
  position: relative;
  z-index: 999;
  top: 0;
  bottom: 0;
  transition: all 300ms;
}
.sideNew .outer {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  overflow: hidden;
  transition: all 300ms;
}
.sideNew .inner {
  position: relative;
  height: 100%;
  overflow-x: visible;
  overflow-y: scroll;
  transition: all 300ms;
  -webkit-overflow-scrolling: touch;
}
.sideNew .drag-bar {
  position: absolute;
  top: 0;
  width: 10px;
  height: 100%;
  right: 0;
  cursor: col-resize;
  z-index: 1;
}
.sideNew .drag-bar-icon {
  position: absolute;
  top: 50%;
  margin-top: -50px;
  right: -9px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px 0;
  width: 6px;
  height: 100px;
  border-left: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  cursor: col-resize;
}
.sideNew .drag-bar-icon span {
  display: block;
  width: 2px;
  height: 2px;
  background: #bbb;
  border-radius: 50%;
}
