@headerHeight: 54px;
@primary-color: #A0C63B;
@active-background-color: #006939;

.header-menu {
    display: block;
    height: 100%;

    :global {
        .ant-menu-root {
            border: 0;
            height: @headerHeight;
            line-height: @headerHeight - 3;
            color: #fff;
        }

        .ant-menu-item > a {
            color: #fff;
        }

        .font-icon {
            margin-right: 8px;
        }

        .ant-menu-item.ant-menu-item-selected > a,
        .ant-menu-item.ant-menu-item-active > a {
            color: #fff;
        }

        .ant-menu-item.ant-menu-item-selected,
        .ant-menu-item.ant-menu-item-active,
        .ant-menu-submenu-selected,
        .ant-menu-submenu-open {
            background-color: #A0C63B;
            border-bottom: 2px solid rgba(255, 255, 255, 0) !important;

            & .ant-menu-submenu-title {
                color: #fff;
            }
        }
    }
}

// 这里用了个全局样式
:global {
    .ant-menu-submenu-popup .font-icon {
        margin-right: 8px;
    }

    .ant-menu-submenu-popup {
        background: @primary-color;

        .ant-menu {
            background-color: transparent;

            span {
                color: #fff;
            }
        }

        .ant-menu-item.ant-menu-item-selected,
        .ant-menu-item.ant-menu-item-active {
            background-color: @active-background-color !important;
        }

        .ant-menu-submenu-arrow {
            &::before,
            &::after {
                background-image: linear-gradient(to right, #fff, #fff) !important;
            }
        }
    }
}

@primary-color: #A0C63B;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;