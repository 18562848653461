/*
全局样式，不要轻易编写全局样式
*/
@import "//at.alicdn.com/t/font_1941350_h1bkay939hc.css";

html,
body,
#root {
    height: 100%;
}

@page {
    size: auto;
    margin: 0mm;
}

body {
    margin: 0;
    padding: 0;
    // font-family: "Source Sans Pro", "Helvetica Neue", sans-serif, "Helvetica",
    //     "PingFang SC", "Microsoft YaHei", "Arial";
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #7E8B97;

}

/**滚动条*/
::-webkit-scrollbar {
    width: 4px;
    height: 7px;
}

::-webkit-scrollbar-thumb {
    background: #D2DAE1;
    border-radius: 6px;
}

::-webkit-scrollbar-track {
    border-radius: 6px;
    background: rgba(250, 250, 250, 0.9);
}

/* .ant-menu-item > a {
    color: #fff !important;
} */
#nprogress .bar {
    z-index: 9999;
}

.ant-table-thead {
    background-color: #ffffff !important;

}

:global(.anticon)::before {
    font-family: "PingFang SC" !important;
    // font-family: "anticon", "anticon-docpark" !important;
    display: inline-block;
}


.ant-form label,
.ant-form-explain {
    font-size: 12px;
}

/*.ant-form-item-label,*/
/*.ant-form-item-control {*/
/*line-height: 25px;*/
/*}*/

/*.ant-tooltip,*/
/*.ant-modal-mask,*/
/*.ant-modal-wrap,*/
/*.ant-message,*/
/*.ant-menu-submenu-popup,*/
/*.ant-notification {*/
/*z-index: 9999 !important;;*/
/*}*/

/*.ant-modal {*/
/*padding-bottom: 84px !important;*/
/*}*/

/* 只在打印时显示 */
.just-print {
    display: none !important;
}

@media print {
    body {
        padding: 0 !important;
        background: none;
    }

    /* 打印时不显示 */
    .no-print {
        display: none !important;
    }

    .just-print {
        display: block !important;
    }

    .ant-message {
        display: none !important;
    }

    .ant-modal-mask {
        display: none !important;
    }
}



.flex_layout_contianer {
    flex: 1;
    overflow: hidden;
}

.common_layout_container {
    position: "absolute";
    top: "50px";
    left: 0;
    height: "calc(100% - 74px)";
    width: "100%";
    overflow: "hidden";
}

.common_ant_modal {
    height: 600px;
    overflow: auto;
}

/* .ant-modal-body {
    height: 500px;
    overflow: auto;
} */

/* .ant-table-body {
    min-height: 420px;
} */

.flod_icon {
    position: absolute;
    height: 34px;
    width: 34px;
    line-height: 34px;
    border: 0;
    border-left: 0;
    border-radius: 50%;
    z-index: 1;
    display: none;
}

.more_query {
    overflow: hidden;
    transition: all 0.6s;
}

.header_layout {
    margin-bottom: 12px;
}

.content_layout {
    background-color: #fff;
    flex: 1;
}

.sider_layout {
    margin-right: 12px;
}

.common_color {
    background-color: #eff2f5 !important;
}

.ant-tabs-content {
    height: calc(100% - 70px);
}

/* .ant-tabs-top-content > .ant-tabs-tabpane {
    flex-shrink: 1 !important;
} */

.print_hide {
    visibility: hidden !important;
    display: none !important;
}

// New style start
.ant-table-thead>tr>th,
.ant-table-tbody>tr>td {
    padding: 12px 12px !important;
    overflow-wrap: break-word;
    font-size: 12px;
}

.ant-table-thead>tr>th {
    color: #000;
}

.ant-table-bordered .ant-table-thead>tr>th,
.ant-table-bordered .ant-table-tbody>tr>td {
    border-right: 0px;
}

.ant-table-bordered .ant-table-header>table,
.ant-table-bordered .ant-table-body>table,
.ant-table-bordered .ant-table-fixed-left table,
.ant-table-bordered .ant-table-fixed-right table {
    border: 0px;
}

.ant-table-placeholder,
.ant-card-head {
    border-bottom: 0px;
}

.ant-form label,
.ant-form-explain {
    font-size: 14px;
}

.ant-tree-node-selected {
    width: 90%;
}

.ant-tree-node-selected {
    .ant-tree-title {
        color: #738BF1;
        display: inline-block;
        width: 100%;

    }
}

// .ant-tree li .ant-tree-node-content-wrapper{
//     color: #000 !important;
// }
.ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: rgba(47, 84, 235, 0) !important;
}

// .ant-tree li ul li .ant-tree-node-content-wrapper-normal {
//     font-size: 12px !important;
//     color: #7E8B97 !important;
// }
.ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon {
    color: #B2B9C1 !important;
    font-size: 14px !important;
}

.ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
:root .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon {
    color: #B2B9C1 !important;
    font-size: 14px !important;
}

.ant-tree li ul {
    padding: 0px !important;
}

.treeList .ant-tree li ul {
    padding-left: 15px !important;
}

.ant-tabs-bar,
.ant-form {
    background-color: #ffffff;
}

.ant-card-head-title {
    padding-bottom: 0px !important;
    // padding-top: 30px !important;
}

.ant-card-head {
    border-bottom: 0px !important;
    padding: 0 30px !important;
}

.ant-form-item-label {
    text-align: left !important;
    text-overflow: ellipsis;
}

.line-btn:after {
    /*伪元素是行内元素 正常浏览器清除浮动方法*/
    content: "";
    display: block;
    height: 0;
    clear: both;
    visibility: hidden;
}

.clearfix {
    *zoom: 1;
    /*ie6清除浮动的方式 *号只有IE6-IE7执行，其他浏览器不执行*/
}

.report-setting {
    margin-right: 0px;
}

.ant-tabs-nav-container {
    padding: 0 30px;
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
    overflow-y: hidden;
}

.ant-layout {
    overflow: hidden;
}

.ant-card,
.ant-spin-nested-loading,
.ant-spin-container {
    height: 100% !important;
    position: initial !important;
}

.layoutMargin {
    margin: 0 16px 15px 16px !important;

    .ant-card-body {
        padding: 20px 30px 10px !important;
    }

    .ant-layout-sider-children {
        .ant-input-search {
            .ant-input {
                background: #F8FAFB;
                border-radius: 8px;
                border: 0;
                min-height: 36px;
                line-height: 36px;
                // font-size: 12px;
            }
        }
    }

    .ant-input-number,
    .ant-input,
    .ant-select-selection {
        min-height: 36px;
        border: 1px solid #E7EDF3;
        background: #F8FAFB;
        border-radius: 8px;
        // font-size: 12px;
        line-height: 36px;
        // color: #7E8B97;
    }

    .ant-select-selection__rendered {
        line-height: 36px;
    }

    .ant-tabs-bar {
        border: 0;

        // margin-bottom: 20px;
        .ant-tabs-nav-scroll {
            font-size: 16px;
        }
    }

    .ant-card-bordered {
        border: 0px;
        border-radius: 20px;
        position: relative !important;
    }

    .searchbg {
        background-color: #fff;
        margin-bottom: 20px;
        border-radius: 20px;
        padding: 20px 30px;
    }


    .tabsbg {
        height: 100%;
        background: #fff;
        border-radius: 20px;
        position: initial
    }

}

.ant-breadcrumb {
    padding: 16px;
    font-size: 12px;
    padding-left: 35px;
    color: #7E8B97;
}

.ant-breadcrumb>span:last-child a {
    color: #000;
}

.searchbtn {
    position: absolute;
    top: 70px;
    right: 16px;
}

.searchFormContainer {
    background-color: #fff;
    padding: 15px 30px;
    margin-bottom: 8px;
    border-radius: 20px;
}

.ant-tabs-tabpane {
    .searchFormContainer {
        background-color: #fff;
        padding: 10px 30px;
        margin-bottom: 0;
        border-bottom: 1px solid #E8EEF3;
        border-radius: 0;
    }

    .searchbg {
        background-color: #fff;
        margin-bottom: 0;
        border-radius: 0;
        padding: 20px 30px 10px;
        border-bottom: 1px solid #E8EEF3;
    }
}

.ant-btn {
    height: 32px;
    border-radius: 8px;
    font-size: 12px;
    ;
    color: #7E8B97;
    border: 1px solid #E7EDF3;
}

.ant-btn-lg {
    height: 40px;
    font-size: 16px;
}

.ant-modal-content {
    border-radius: 16px;
    box-shadow: 0px 13px 30px rgba(44, 47, 50, 0.08);

    .ant-modal-header {
        border-radius: 16px 16px 0 0;
    }

    .ant-input-search {
        .ant-input {
            border-radius: 8px;
            color: #7E8B97;
        }
    }

    .ant-layout-sider-children {
        .ant-input-search {
            .ant-input {
                background: #F8FAFB;
                border-radius: 8px;
                border: 0;
                min-height: 36px;
                line-height: 36px;
                font-size: 12px;
                color: #7E8B97;
            }
        }
    }

    .ant-input-number,
    .ant-input,
    .ant-select-selection {
        min-height: 36px;
        border: 1px solid #E7EDF3;
        background: #F8FAFB;
        border-radius: 8px;
        font-size: 12px;
        line-height: 36px;
        color: #7E8B97;
    }

    .ant-select-selection__rendered {
        line-height: 36px;
    }
}

.modalshelft {
    .ant-modal-content {
        .ant-modal-footer {
            text-align: center;
            border: 0;
        }
    }
}

.mobilepreviewliest {
    .viewer-container {
        width: 100% !important;
        height: 200px !important;

        .viewer-canvas {
            img {
                height: 100% !important;
                margin-left: 10px !important;
                margin-top: 10px !important;
                width: 100% !important;
            }
        }

        .viewer-toolbar {
            display: none;
        }
    }

}

.docDescribe {
    .ant-form-item {
        .ant-form-item-label {
            text-align: left !important;
        }

        .ant-form-item-label>label {
            color: #7E8B97;
        }
    }
}

.ant-pagination-options {
    .ant-select-selection--single {
        min-height: 30px;
        border: 1px solid #E7EDF3;
        background: #F8FAFB;
        border-radius: 8px;
        line-height: 30px;
    }

    .ant-select-selection__rendered {
        line-height: 30px;
    }
}

/*

档案室实景视图样式*/
.listbg0,
.listbg6,
.listbg12 {
    background: rgba(95, 69, 244, 0.08);

    .list_action0,
    .list_action6,
    .list_action12 {
        background: rgb(95, 69, 244);
    }
}

.listbg1,
.listbg7,
.listbg13 {
    background: rgba(0, 125, 253, 0.08);

    .list_action1,
    .list_action7,
    .list_action13 {
        background: rgb(0, 125, 253);
    }
}

.listbg2,
.listbg8,
.listbg14 {
    background: rgba(247, 176, 0, 0.08);

    .list_action2,
    .list_action8,
    .list_action14 {
        background: rgb(247, 176, 0);
    }
}

.listbg3,
.listbg9,
.listbg15 {
    background: rgba(226, 117, 117, 0.08);

    .list_action3,
    .list_action9,
    .list_action15 {
        background: rgb(226, 117, 117);
    }
}

.listbg4,
.listbg10,
.listbg16 {
    background: rgba(57, 189, 61, 0.08);

    .list_action4,
    .list_action10,
    .list_action16 {
        background: rgb(57, 189, 61);
    }
}

.listbg5,
.listbg11,
.listbg17 {
    background: rgba(75, 186, 225, 0.08);

    .list_action5,
    .list_action11,
    .list_action17 {
        background: rgb(75, 186, 225);
    }
}

.listchecked0,
.listchecked6,
.listchecked12 {
    background-color: rgba(95, 69, 244) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listchecked1,
.listchecked7,
.listchecked13 {
    background-color: rgba(0, 125, 253) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listchecked2,
.listchecked8,
.listchecked14 {
    background-color: rgba(247, 176, 0) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listchecked3,
.listchecked9,
.listchecked15 {
    background-color: rgba(226, 117, 117) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listchecked4,
.listchecked10,
.listchecked16 {
    background-color: rgba(57, 189, 61) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listchecked5,
.listchecked11,
.listchecked17 {
    background-color: rgba(75, 186, 225) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listbg0:hover,
.listbg6:hover,
.listbg12:hover {
    background-color: rgba(95, 69, 244) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listbg1:hover,
.listbg7:hover,
.listbg13:hover {
    background-color: rgba(0, 125, 253) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listbg2:hover,
.listbg8:hover,
.listbg14:hover {
    background-color: rgba(247, 176, 0) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listbg3:hover,
.listbg9:hover,
.listbg15:hover {
    background-color: rgba(226, 117, 117) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listbg4:hover,
.listbg10:hover,
.listbg16:hover {
    background-color: rgba(57, 189, 61) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.listbg5:hover,
.listbg11:hover,
.listbg17:hover {
    background-color: rgba(75, 186, 225) !important;
    color: #fff;

    .spanNum {
        color: #fff !important;
    }
}

.viewTree {
    padding: 0 20px !important;

    li {
        position: relative;
    }

    li .ant-tree-node-content-wrapper {
        height: 36px !important;
        line-height: 36px !important;
        padding: 0 12px 0 30px !important;
        width: 100%;
    }

    li span.ant-tree-switcher {
        position: absolute !important;
        left: 5px;
        line-height: 36px !important;
    }

    li span.ant-tree-switcher_open {
        color: #006939;
    }

    li .ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-open {
        background: #f0f7ff !important;
        border-radius: 10px !important;
        color: #006939 !important;
    }

    .ant-tree-child-tree-open {
        li .ant-tree-node-content-wrapper.ant-tree-node-selected {
            background: none !important;
            border-radius: 0 !important;
        }

        li .ant-tree-node-content-wrapper {
            // height: 24px !important;
            // line-height: 24px !important;
            // padding: 0 0 0 13px !important;
        }

        .ant-tree-node-selected .ant-tree-title {
            color: #006939;
        }
        .ant-tree-treenode-switcher-close,
        .ant-tree-treenode-switcher-open{
            margin-left: 20px;
            height: 36px !important;
            line-height: 36px !important;
        } 

        // .ant-tree-treenode-switcher-open li .ant-tree-node-content-wrapper {
        //     height: 24px !important;
        //     line-height: 24px !important;
        // }
        // .ant-tree-title{
        //     font-size: 12px;
        // }
    }
}

.ant-dropdown-menu {
    padding: 4px 10px;
    border: 1px solid #E8EEF3;
    box-shadow: 0px 13px 30px rgba(44, 47, 50, 0.08);
    border-radius: 8px !important;
}

.ant-dropdown-menu-item:hover {
    background: #F8FAFB !important;
    border-radius: 4px;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-item>a {
    text-align: center;
    color: #000;
}

.ant-dropdown-menu-item:first-child {
    margin-top: 10px;
}

.ant-dropdown-menu-item:last-child {
    margin-bottom: 10px;
}

.ant-dropdown-menu-item>a:hover {
    color: #006939
}

// .ant-table{
//     color: #7E8B97;
// }
// .ant-table-thead > tr, .ant-table-tbody > tr:nth-child(2n){
//     background-color: #F8FAFB;
// }
.ant-table-tbody>tr>td {
    border-bottom: none !important;
}

.ant-checkbox-inner {
    border: 1px solid #D8DCE0;
    border-radius: 4px;
}

// 表格分页
.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
    border: 1px solid #E8EEF3;
    border-radius: 4px;
    min-width: 34px;
    height: 34px;
    line-height: 34px;
    margin-right: 10px;

    .ant-pagination-item a {
        color: #7E8B97 !important;
    }
}

.ant-pagination.mini .ant-pagination-item {
    background: #006939;
    border-radius: 4px;
    min-width: 34px;
    height: 34px;
    line-height: 34px;
    margin-right: 10px;

    .ant-pagination-item-active a {
        color: #fff;
    }
}

.ant-pagination-item-active {
    background-color: #006939;
}

.ant-pagination.mini .ant-pagination-prev {
    min-width: 34px;
    height: 34px;
    line-height: 32px;
    margin-right: 10px;
    border: 1px solid #E8EEF3;
}

.ant-pagination.mini .ant-pagination-next {
    min-width: 34px;
    height: 34px;
    line-height: 32px;
    border: 1px solid #E8EEF3;
}

.ant-pagination.mini .ant-pagination-total-text,
.ant-pagination.mini .ant-pagination-simple-pager {
    color: #7E8B97;
    left: 130px;
    position: absolute;
    line-height: 34px;
}

.ant-pagination-total-text {
    color: #7E8B97;
    left: 130px;
    position: absolute;
    line-height: 34px;
}

.ant-pagination-options {
    left: 30px;
    margin-left: 0px;
    position: absolute;
}

.ant-pagination.mini .ant-pagination-options {
    left: 30px;
    margin-left: 0px;
    position: absolute;
}

.ant-pagination-options .ant-select-selection--single {
    min-height: 34px;
    line-height: 34px;
}

.ant-select-selection-selected-value {
    font-size: 12px;
    color: #7E8B97;
}

.ant-select-selection--multiple .ant-select-selection__choice {
    color: #7E8B97;
}

.ant-pagination-options-size-changer.ant-select {
    margin-right: 0px;
}

.ant-pagination-options .ant-select-selection__rendered {
    line-height: 32px;
}

// 表单
.ant-form {
    color: #000;
}

.ant-form-item-label label {
    color: #000;
}

// 查询表单
.formCol:nth-child(3n) {
    .formItems {
        margin-left: 16px;
    }
}

.formCol:nth-child(3n+2) {
    .formItems {
        margin-left: 16px;
    }
}

.formContainer {
    background-color: #fff;
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden
}

.row-table {
    .ant-pagination.mini .ant-pagination-options {
        left: 0px;
        margin-left: 0px;
        position: absolute;
    }
}

.ant-card-extra {
    padding: 20px 0 0 !important;
}

.ant-calendar-picker {
    width: 100% !important;
}

.org-unit-control,
.org-dept-control,
.org-user-control {
    border-radius: 8px;
}

.org-unit-control .data-empty,
.org-dept-control .data-empty,
.org-user-control .data-empty {
    color: #7E8B97;
}

// .ant-select-selection-selected-value{
//     font-size: 12px !important;
// }
.ant-select-selection--multiple>ul>li,
.ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
    margin-top: 8px !important;
}
@transitionDuration: 300ms;
.sideNew {
    position: relative;
    z-index: 999;
    top:0;
    bottom: 0;
    // box-shadow: 0 4px 4px 2px #ddd;
    // background: #000d18; //#2c3b41;
    transition: all @transitionDuration;

    .outer {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: all @transitionDuration;
    }

    .inner {
        position: relative;
        height: 100%;
        overflow-x: visible;
        overflow-y: scroll;
        transition: all @transitionDuration;
        -webkit-overflow-scrolling: touch;
    }


    .drag-bar {
        position: absolute;
        top: 0;
        // right: 430px;
        width: 10px;
        height: 100%;
        right: 0;
        cursor: col-resize;
        z-index: 1;
    }

    .drag-bar-icon {
        position: absolute;
        top: 50%;
        margin-top: -50px;
        right: -9px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        padding: 20px 0;
        width: 6px;
        height: 100px;
        //background: #fff;
        //border: 1px solid #e8e8e8;
        border-left: 0;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        cursor: col-resize;

        span {
            display: block;
            width: 2px;
            height: 2px;
            background: #bbb;
            border-radius: 50%;
        }
    }
}
// New style end
@primary-color: #A0C63B;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;