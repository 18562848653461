@headerHeight: 54px;
@sideWidth: 256px;
@footerHeight: 40px;
@contentTop: @headerHeight;
@contentLeft: @sideWidth;
@contentBottom: @footerHeight;
@borderColor: #d9d9d9;
@transitionDuration: 300ms;
@pageHeadHeight: 61px;
@pageTabsHeight: 35px;

body {
    margin: 0;
    background: #f0f2f5;
    padding-left: @contentLeft;
    transition: padding-left @transitionDuration;
}

.base-frame {
    flex: 0;

    :global(.ant-back-top) {
        right: 20px;
        bottom: 80px;
    }
}

.header {
    position: fixed;
    z-index: 999;
    top: 0;
    right: 0;
    left: 0;
    height: @headerHeight;
    line-height: @headerHeight;
    padding-right: 16px;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);

    .right {
        float: right;
        height: 100%;
    }
}

.logo {
    float: left;
    display: inline-block;
    height: @headerHeight;
    line-height: @headerHeight;
    position: relative;
    //transition: all @transitionDuration;
    background: #002140;
    overflow: hidden;

    h1 {
        color: #fff;
    }
}

.trigger {
    float: left;
    font-size: 20px;
    line-height: @headerHeight;
    cursor: pointer;
    transition: all .3s;
    padding: 0 24px;

    &:hover {
        background: #e6f7ff;
    }
}

.side {
    position: fixed;
    z-index: 999;
    top: @headerHeight;
    left: 0;
    bottom: 0;
    width: 200px;
    box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
    background: #001529;
    transition: all @transitionDuration;

    .outer {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        overflow: hidden;
        transition: all @transitionDuration;
    }

    .inner {
        position: relative;
        height: 100%;
        overflow-x: visible;
        overflow-y: scroll;
        transition: all @transitionDuration;
    }

    :global(.react-draggable) {
        transition: all @transitionDuration;
    }
}

.content-top-space {
    position: relative;
    height: @contentTop;
    background: #f0f2f5;
    transition: all @transitionDuration;

    &.with-fixed-page-head {
        height: @contentTop + @pageHeadHeight;
    }

    &.with-tabs {
        height: @contentTop + @pageTabsHeight;
    }

    &.with-fixed-page-head.with-tabs {
        height: @contentTop + @pageHeadHeight + @pageTabsHeight;
    }
}

.page-head-fixed {
    position: fixed;
    right: 0;
    top: @contentTop;
    background: #fff;
    z-index: 998;

    &.with-tabs {
        top: @contentTop + @pageTabsHeight;
    }
}

.page-tabs {
    position: fixed;
    right: 0;
    top: @contentTop;
    height: @pageTabsHeight;
    background: #fff;
    z-index: 998;
    padding-right: 16px;

    :global(.ant-tabs-bar) {
        padding-top: 7px;
    }
}

.footer {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    height: @footerHeight;
    line-height: @footerHeight;
    text-align: center;
}

.action {
    cursor: pointer;
    padding: 0 12px;
    display: inline-block;
    transition: all @transitionDuration;
    height: 100%;

    > i {
        font-size: 16px;
        vertical-align: middle;
    }

    &:global(.ant-popover-open),
    &:hover {
        background: #e6f7ff;
    }
}

.global-loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: 99999;
    cursor: not-allowed;
    text-align: center;

    :global(.ant-spin) {
        position: absolute;
        top: 30%;
    }
}

@primary-color: #A0C63B;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;