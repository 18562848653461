@primary-color: #1890ff;

.data-block-root {
    display: inline-flex;
    flex: 1;
    height: 80px;
    align-items: stretch;
    border-radius: 5px;
    overflow: hidden;
    background: #fff;
    //box-shadow: 1px 2px 3px 1px #b9b8b8 ;
    //border: 1px solid @primary-color;

    .bgimg {
        width: 100%;
        //background: url(./cirle.png) no-repeat top right;
        background-size: 80%;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon {
            text-align: center;
            width: 60px;
            font-size: 42px;
            padding-left: 15px;
            color: #fff;
            //opacity: .5;
            //background: @primary-color;
        }

        .message {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            color: #fff;

            .count {
                font-size: 30px;
            }

            .tip {
                font-size: 16px;
            }
        }

    }


}

@primary-color: #A0C63B;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;