/* 查询条件 */
.sx-query-bar {
    position: relative;
    margin-bottom: 8px;
    margin-left: -8px;
    margin-right: -8px;
    padding-bottom: 8px;
    background: #fff;
    border-bottom: 8px solid #f0f2f5;

    &.with-collapse {
        padding-right: 55px;
    }

    .sx-query-bar-collapsed {
        position: absolute;
        right: 0;
        top: 0;
        padding: 3px 8px 0 0;
        font-size: 12px;

        i {
            margin-left: 3px;
        }
    }

    .ant-form-item {
        margin-bottom: 0;
    }
}

@primary-color: #A0C63B;@logo-container-color: #367fa9;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 14px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;